<template>
	<div id="Forget">
		<common-header></common-header>
		<div class="reset-box">
			<h1>欢迎使用轻重缓急工具</h1>
			<div class="reset-input">
				<el-form
					:model="Register"
					ref="RegisterForm"
					label-width="0"
					class="RegisterForm"
				>
					<!--                    手机号/邮箱-->
					<el-form-item class="email-box">
						<div>
							<i class="iconfont iconmobilephone"></i>
						</div>
						<el-input
							v-model="Register.account"
							placeholder="手机号/邮箱"
						></el-input>
					</el-form-item>
					<!--                    验证码-->
					<el-form-item prop="test" class="code">
						<div>
							<i class="iconfont iconVerificationCode1"></i>
						</div>
						<el-input
							v-model="Register.code"
							placeholder="验证码"
						></el-input>
						<el-button
							type="button"
							@click="sendcode"
							:disabled="disabled"
							v-if="!disabled"
							class="send"
							>获取验证码</el-button
						>
						<el-button
							v-else
							type="button"
							@click="sendcode"
							:disabled="disabled"
							class="send ncolor"
							>{{ btntxt }}</el-button
						>
					</el-form-item>
					<!--                    密码-->
					<el-form-item prop="paw" class="paw-box">
						<div>
							<i class="iconfont iconpassword"></i>
						</div>
						<el-input
							type="password"
							v-model="Register.password"
							placeholder="密码"
						></el-input>
					</el-form-item>
					<!--                    确认密码-->
					<el-form-item prop="paw" class="paw-box">
						<div>
							<i class="iconfont iconpassword"></i>
						</div>
						<el-input
							type="password"
							v-model="Register.againPassword"
							placeholder="再次输入密码"
						></el-input>
					</el-form-item>
					<el-form-item>
						<el-button
							type="primary"
							@click="submitForm"
							class="reset-btn"
							>重置密码</el-button
						>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="copyright">
			©2020-2021轻重缓急All Rights Reserved
			<a href="https://beian.miit.gov.cn/" target="_blank"
				>粤ICP备15072947号-6
			</a>
		</div>
	</div>
</template>

<script>
import CommonHeader from '@/components/CommonHeader'
export default {
	name: 'Forget',
	components: { CommonHeader },
	data() {
		return {
			Register: {
				account: '', // 邮箱
				code: '', // 邮箱验证码
				password: '', // 密码
				againPassword: '', // 再次输入密码
			},
			disabled: false,
			textedTime: 0, // 等待60s
			btntxt: '重新发送',
		}
	},
	methods: {
		submitForm() {
			this.$http({
				url: this.api.findPassWord,
				data: {
					account: this.Register.account,
					code: this.Register.code,
					password: this.Register.password,
					repassword: this.Register.againPassword,
				},
			})
				.then((res) => {
					console.log('重置密码', res)
					this.$message.success({
						message: res.msg,
						center: true,
					})

					if (res.code === 0) {
						this.Register.account = ''
						this.Register.code = ''
						this.Register.password = ''
						this.Register.againPassword = ''
					}
				})
				.catch((err) => console.log(err))
		},
		// 发送验证码
		sendcode() {
			let str = this.Register.account
			let reg = RegExp(/@/)
			// 判断是否是邮箱
			let isEmail = reg.test(str)
			let url = ''
			let data = {}
			if (isEmail) {
				url = this.api.getEmail
				data.email = this.Register.account
			} else {
				url = this.api.get_phone_code
				data.phone = this.Register.account
			}
			// 发送成功
			this.$http({ url, data })
				.then((res) => {
					if (res.code === 1) {
						this.$message.warning({
							message: res.msg,
							center: true,
						})
					} else if (res.code === 0) {
						this.$message.success({
							message: res.msg,
							center: true,
						})
						this.textedTime = 60
						this.disabled = true
						this.timer()
					}
					console.log('获取验证码', res)
				})
				.catch((err) => console.log(err))
		},
		// 60S倒计时
		timer() {
			if (this.textedTime > 0) {
				this.textedTime--
				this.btntxt = this.textedTime + 's后重新获取'
				setTimeout(this.timer, 1000)
			} else {
				this.textedTime = 0
				this.btntxt = '获取验证码'
				this.disabled = false
			}
		},
	},
}
</script>

<style lang="less">
#Forget {
	min-width: 1200px;
	height: 100vh;
	background: #f9f9f9;
	.el-button {
		padding: 0 0;
	}
	.reset-box {
		width: 1200px;
		height: 680px;
		min-width: 1200px;
		margin: 77px auto 0 auto;
		background: #fff;

		h1 {
			font-size: 36px;
			color: #70ccff;
			text-align: center;
			padding-top: 140px;
			font-weight: normal;
		}

		.reset-input {
			width: 368px;
			margin: 108px auto 0 auto;

			input::-webkit-input-placeholder,
			textarea::-webkit-input-placeholder {
				color: #c1c1c1;
			}

			input:-moz-placeholder,
			textarea:-moz-placeholder {
				color: #c1c1c1;
			}

			input::-moz-placeholder,
			textarea::-moz-placeholder {
				color: #c1c1c1;
			}

			input:-ms-input-placeholder,
			textarea:-ms-input-placeholder {
				color: #c1c1c1;
			}
			.RegisterForm {
				.email-box,
				.code,
				.paw-box {
					.iconfont {
					}
					margin-bottom: 30px;
					div:first-of-type {
						width: 24px;
						height: 24px;
						display: flex;
						align-items: center;
					}
				}
				.code {
					position: relative;
					margin-bottom: 30px;
					.send {
						width: 120px;
						height: 30px;
						background: #70ccff;
						font-size: 14px;
						color: #fffefe;
						text-align: center;
						position: relative;
						left: 190px;
						bottom: 8px;
						border: none;
						span {
							display: block;
							width: 120px;
							height: 30px;
							line-height: 30px;
						}
					}
					.ncolor {
						background: #d3d3d3;
					}
					.el-button--button {
						display: inline-block;
						width: 120px;
						height: 30px;
					}
				}
				.reset-btn {
					margin-top: 40px;
					width: 368px;
					height: 46px;
					font-size: 22px;
					line-height: 46px;
					border-radius: 6px;
					text-align: center;
					color: #fffefe;
					background: #70ccff;
					cursor: pointer;
					border: none;
				}
			}
			input {
				font-size: 16px;
				width: 338px;
				height: 24px;
				background: none;
				outline: none;
				border: none;
				border-bottom: 1px solid #d3d3d3;
				padding-left: 6px;
				margin-left: 5px;
				padding-right: 130px;
				box-sizing: border-box;
			}

			.iconfont {
				color: #5d5d5d;
				font-size: 24px;
			}

			.sendtxt .send-time {
				background: #d3d3d3;
			}
		}
	}
}
.copyright {
    margin-top: 400px;
	box-sizing: border-box;
	text-align: center;
	padding: 30px 0;
	font-size: 14px;
}
</style>
